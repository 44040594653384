<template>
    <v-card>
        <v-toolbar color="grey darken-3" dark elevation="1">
            <v-toolbar-title>
                {{ title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y v-model="menu">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            class="grey darken-3"
                            icon
                            outlined
                            :disabled="isNew"
                            v-bind="attrs"
                            v-on="on"
                            :loading="getModalLoading"
                            @click="menu = true"
                    >
                        <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
                    </v-btn>
                </template>
                <v-list dense rounded nav>
                    <v-list-item link class="px-4" @click.stop="deleteDialog">
                        <v-list-item-icon>
                            <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-toolbar>
        <v-card-text class="pt-4">
            <v-tabs class="custom-tabs-transparent" color="success" v-model="tabs">
                <v-tab class="text-left justify-start">
                    Основні
                </v-tab>
                <v-tab class="text-left justify-start">
                    Додаткові
                </v-tab>
                <v-tab-item class="pa-0 px-1">
                    <v-form v-model="formValid" ref="form">
                        <v-row class="dense-wrapper mt-2">
                            <v-col cols="12" md="12">
                                <v-select type="text"
                                          filled
                                          :items="services"
                                          label="Послуга"
                                          v-model="service_id"
                                          hide-details
                                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                          :class="service_id ? '' : 'req-star'"
                                          color="grey"/>

                            </v-col>
                            <v-col cols="6" md="6">
                                <v-switch
                                        v-model="disable_parent_service"
                                        inset
                                        hide-details
                                        class="mt-1" color="success"
                                        :label="disable_parent_service ? 'Не враховувати до осн.послуги' : 'Враховувати до осн.послуги'"
                                />
                            </v-col>
                            <v-col cols="6" md="6">
                                <v-switch
                                        v-model="disable_current_service"
                                        inset
                                        hide-details
                                        class="mt-1" color="success"
                                        :label="disable_current_service ? 'Не враховувати до залеж.послуги' : 'Враховувати до залеж.послуги'"
                                />
                            </v-col>
                            <v-col cols="6" md="6">
                                <CounterModel :value="model_id" @autocompleteChange="modelChange"
                                              required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                              :class="model_id ? '' : 'req-star'"
                                />
                            </v-col>
                            <v-col cols="6" md="6">
                                <v-text-field type="text"
                                              filled
                                              label="Серійний номер"
                                              v-model="serial_number"
                                              hide-details
                                              required :rules="[v => !!v || 'Це поле є обов’язковим']"
                                              :class="serial_number ? '' : 'req-star'"
                                              color="grey"/>
                            </v-col>
                            <v-col cols="6" md="6">
                                <date-component v-model="date_start" @afterSaveDate="setVerification" req
                                                label="Дата встановлення" :class_="date_start ? '' : 'req-star'"
                                />
                            </v-col>
                            <v-col cols="6" md="6">
                                <date-component v-model="date_end" label="Дата демонтажу"/>
                            </v-col>
                            <v-col cols="6" md="6">
                                <date-component v-model="prev_verification" :req="false" label="Попередня повірка"/>
                            </v-col>
                            <v-col cols="6" md="6">
                                <date-component v-model="next_verification" :req="false" label="Наступна повірка"/>
                            </v-col>
                            <v-col cols="6" md="6">
                                <v-switch
                                        v-model="zones_counter"
                                        inset
                                        hide-details
                                        class="ml-2"
                                        @change="changeZonezCounter"
                                        :label="zones_counter ? 'Облік зон лічильника' : 'Зонність не передбачена'"
                                />
                            </v-col>
                            <v-col cols="6" md="6">
                                <v-text-field type="number"
                                              min="0"
                                              max="3"
                                              filled
                                              label="Кількість зон"
                                              v-model.number="zone"
                                              v-decimal
                                              hide-details
                                              :disabled="!zones_counter"
                                              required
                                              :rules="zones_counter ? [v => !!v || 'Це поле є обов’язковим'] : [() => true]"
                                              :class="zones_counter ? zone ? '' : 'req-star' : ''"
                                              color="grey"/>
                            </v-col>
                            <template v-if="!zone || zone === 1">
                                <v-col :cols="isNew ? 12 : 6">
                                    <v-text-field type="text"
                                                  filled
                                                  label="Початковий показник"
                                                  v-decimal
                                                  v-model.number="start_meter_reading_first"
                                                  hide-details
                                                  required :rules="[v => +v >= 0 || 'Це поле є обов’язковим']"
                                                  :class="start_meter_reading_first >= 0 ? '' : 'req-star'"
                                                  :disabled="isNew ? false : !change_start_reading"
                                                  color="grey"/>
                                </v-col>
                                <v-col cols="6" v-if="!isNew">
                                    <v-switch v-model="change_start_reading" hide-details label="Змінити початковий?"
                                              color="success" class="pt-0"/>
                                </v-col>
                            </template>
                            <template v-if="zone === 2">
                                <v-col :cols="isNew ? 12 :6" class="pt-2 pb-1">
                                    <span class="subtitle-1">Початкові показники</span>
                                </v-col>
                                <v-col cols="6" class="pt-2 pb-1" v-if="!isNew">
                                    <v-switch v-model="change_start_reading"
                                              label="Змінити початковий?"
                                              hide-details
                                              color="success"
                                              class="pt-0 mt-0"
                                    />
                                </v-col>
                                <v-col cols="6" md="6" class="pt-1">
                                    <v-text-field type="text"
                                                  filled
                                                  label="Зона №1"
                                                  v-decimal
                                                  v-model.number="start_meter_reading_first"
                                                  hide-details
                                                  required :rules="[v => +v >= 0 || 'Це поле є обов’язковим']"
                                                  :class="start_meter_reading_first >= 0 ? '' : 'req-star'"
                                                  :disabled="isNew ? false : !change_start_reading"
                                                  color="grey"/>
                                </v-col>
                                <v-col cols="6" md="6" class="pt-1">
                                    <v-text-field type="text"
                                                  filled
                                                  label="Зона №2"
                                                  v-decimal
                                                  v-model.number="start_meter_reading_second"
                                                  hide-details
                                                  required :rules="[v => +v >= 0 || 'Це поле є обов’язковим']"
                                                  :class="start_meter_reading_second >= 0 ? '' : 'req-star'"
                                                  :disabled="isNew ? false : !change_start_reading"
                                                  color="grey"/>
                                </v-col>
                            </template>
                            <template v-if="zone === 3">
                                <v-col :cols="isNew ? 12 : 6" class="pt-2 pb-1">
                                    <span class="subtitle-1">Початкові показники</span>
                                </v-col>
                                <v-col cols="6" class="pt-2 pb-1" v-if="!isNew">
                                    <v-switch v-model="change_start_reading"
                                              label="Змінити початковий?"
                                              hide-details
                                              color="success"
                                              class="pt-0 mt-0"
                                    />
                                </v-col>
                                <v-col cols="12" md="4" class="pt-1">
                                    <v-text-field type="text"
                                                  filled
                                                  label="Зона №1"
                                                  v-decimal
                                                  v-model.number="start_meter_reading_first"
                                                  hide-details
                                                  required :rules="[v => +v >= 0 || 'Це поле є обов’язковим']"
                                                  :class="start_meter_reading_first >= 0 ? '' : 'req-star'"
                                                  :disabled="isNew ? false : !change_start_reading"
                                                  color="grey"/>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-1">
                                    <v-text-field type="text"
                                                  filled
                                                  label="Зона №2"
                                                  v-decimal
                                                  v-model.number="start_meter_reading_second"
                                                  hide-details
                                                  required :rules="[v => +v >= 0 || 'Це поле є обов’язковим']"
                                                  :class="start_meter_reading_second >= 0 ? '' : 'req-star'"
                                                  :disabled="isNew ? false : !change_start_reading"
                                                  color="grey"/>
                                </v-col>
                                <v-col cols="12" md="4" class="pt-1">
                                    <v-text-field type="text"
                                                  filled
                                                  label="Зона №3"
                                                  v-decimal
                                                  v-model.number="start_meter_reading_third"
                                                  hide-details
                                                  required :rules="[v => +v >= 0 || 'Це поле є обов’язковим']"
                                                  :class="start_meter_reading_third >= 0 ? '' : 'req-star'"
                                                  :disabled="isNew ? false : !change_start_reading"
                                                  color="grey"/>
                                </v-col>
                            </template>
                        </v-row>
                    </v-form>
                </v-tab-item>
                <v-tab-item class="pa-0">
                    <v-row class="dense-wrapper mt-2 mb-1">
                        <v-col cols="12" md="6">
                            <v-text-field type="text"
                                          filled
                                          label="№ пломби"
                                          v-model="seal_number_primary"
                                          hide-details
                                          color="grey"/>
                        </v-col>
                        <v-col cols="12" md="6">
                            <date-component v-model="seal_date_primary" label="Дата опломбування"/>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field type="text"
                                          filled
                                          label="№ пломби (мембрана)"
                                          v-model="seal_number_secondary"
                                          hide-details
                                          color="grey"/>
                        </v-col>
                        <v-col cols="12" md="6">
                            <date-component v-model="seal_date_secondary" label="Дата опломбування"/>
                        </v-col>
                        <v-col cols="12" md="12">
                            <CounterInstallationPlace :value="counter_installation_place_id"
                                                      @autocompleteChange="counterInstallationPlaceChange"
                            />
                        </v-col>
                        <v-col cols="12" md="12">
                            <CounterContractor :value="counter_contractor_id"
                                               @autocompleteChange="counterContractorChange"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <UnitType :value="unit_type_id"
                                      @autocompleteChange="unitTypeChange"
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field type="text"
                                          filled
                                          label="Коефіцієнт втрат"
                                          v-model="lost_coefficient"
                                          hide-details v-decimal
                                          color="grey"/>
                        </v-col>
                        <v-col cols="12">
                            <FlatCounter
                                :value="counter_minus_id"
                                :flat_id="flat_id"
                                :service_id="service_id"
                                :filled="true"
                                label="Лічильник (об'єм мінус)"
                                :autosync="false"
                                :clearable="true"
                                :fetch_first="false"
                                @autocompleteChange="flatCounterChange"
                                color="grey"/>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
                   class="button-accept">
                <v-icon left>mdi-content-save</v-icon>
                Зберегти
            </v-btn>
            <v-btn depressed text tile color="grey" @click="closeModal">
                <v-icon left>mdi-close</v-icon>
                Відмінити
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

import {
  CREATE_FLAT_COUNTER, UPDATE_FLAT_COUNTER, REMOVE_FLAT_COUNTER, GET_ALL_FLAT_COUNTER_OPERATION
} from "@/store/actions/counter";
import {mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";
import {addMothsToStringDate} from "@/utils/icons"

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'flat_counter_modal_delete'

export default {
    name: "HWP_Modal_FlatCounter",
    mixins: [ModalComponentMixin],
    components: {
        CounterModel: () => import("@/components/autocomplite/CounterModel"),
        CounterContractor: () => import("@/components/autocomplite/AC_CounterContractor.vue"),
        CounterInstallationPlace: () => import("@/components/autocomplite/AC_CounterInstallationPlace.vue"),
        UnitType: () => import('@/components/autocomplite/AC_UnitType.vue'),
        FlatCounter: () => import("@/components/autocomplite/FlatCounter.vue")
    },
    data() {
        return {
            tabs: 0,
            flat_id: this.item.flat_id,
            zone: this.item.zone || 0,
            service_id: this.item.service_id || 0,
            start_meter_reading_first: this.item.start_meter_reading_first || 0,
            start_meter_reading_second: this.item.start_meter_reading_second || 0,
            start_meter_reading_third: this.item.start_meter_reading_third || 0,
            date_start: this.item.date_start || null,
            date_end: this.item.date_end || null,
            prev_verification: this.item.prev_verification || null,
            next_verification: this.item.next_verification || null,
            verification_term: this.item.verification_term || null,
            serial_number: this.item.serial_number || "",
            model_id: this.item.model_id || 0,
            disable_parent_service: this.item.disable_parent_service || false,
            disable_current_service: this.item.disable_current_service || false,
            active: this.item.active === undefined ? true : this.item.active,
            zones_counter: !(this.zone === undefined || this.zone === 0),
            change_start_reading: false,
            seal_number_primary: this.item.seal_number_primary || null,
            seal_number_secondary: this.item.seal_number_secondary || null,
            seal_date_primary: this.item.seal_date_primary || null,
            seal_date_secondary: this.item.seal_date_secondary || null,
            counter_contractor_id: this.item.counter_contractor_id || null,
            unit_type_id: this.item.unit_type_id || null,
            lost_coefficient: this.item.lost_coefficient || null,
            counter_installation_place_id: this.item.counter_installation_place_id || null,
            counter_minus_id: this.item.counter_minus_id || null,
        }
    },
    methods: {
        setVerification(payload) {
            if (payload && this.verification_term) {
                this.prev_verification = payload
                this.next_verification = addMothsToStringDate(this.prev_verification, this.verification_term * 12)
            }
        },
        closeModal(payload = null) {
            this.$emit('closeModal', payload)
            this.itemId = this.item.id
            this.flat_id = this.item.flat_id
            this.zone = this.item.zone || 0
            this.service_id = this.item.service_id || 0
            this.start_meter_reading_first = this.item.start_meter_reading_first || 0
            this.start_meter_reading_second = this.item.start_meter_reading_second || 0
            this.start_meter_reading_third = this.item.start_meter_reading_third || 0
            this.date_start = this.item.date_start || null
            this.date_end = this.item.date_end || null
            this.prev_verification = this.item.prev_verification || null
            this.next_verification = this.item.next_verification || null
            this.verification_term = this.item.verification_term || null
            this.serial_number = this.item.serial_number || ""
            this.model_id = this.item.model_id || 0
            this.disable_parent_service = this.item.disable_parent_service || false
            this.disable_current_service = this.item.disable_current_service || false
            this.zones_counter = !(this.item.zone === undefined || this.item.zone === 0)
            this.active = this.item.active === undefined ? true : this.item.active
            this.change_start_reading = false
            this.seal_number_primary = this.item.seal_number_primary || null
            this.seal_number_secondary = this.item.seal_number_secondary || null
            this.seal_date_primary = this.item.seal_date_primary || null
            this.seal_date_secondary = this.item.seal_date_secondary || null
            this.counter_contractor_id = this.item.counter_contractor_id || null
            this.unit_type_id = this.item.unit_type_id || null
            this.lost_coefficient = this.item.lost_coefficient || null
            this.counter_installation_place_id = this.item.counter_installation_place_id || null
            this.counter_minus_id = this.item.counter_minus_id || null
            this.tabs = 0

            if (this.$refs.form) {
                this.$refs.form.resetValidation()
            }
            // this.$emit('closeModal')
        },
        deleteDialog() {
            this.menu = false
            const payload = {
                text: 'Підтвердіть вилучення лічильника',
                accept_button: true,
                id: modalDeleteId
            }
            this.$store.dispatch(QUESTION_SHOW, payload)
        },
        crud_item() {
            this.formValid = this.$refs.form.validate()
            if (!this.formValid) {
                this.formValid = false
                this.$store.commit(ALERT_SHOW, {
                    text: 'Послуга, Модель, Серійний номер, Дата встановлення - мають бути заповнені',
                    color: 'error lighten-1'
                })
                return
            }

            const payload = {
                flat_id: this.flat_id,
                service_id: this.service_id,
                zone: this.zone,
                start_meter_reading_first: this.start_meter_reading_first,
                start_meter_reading_second: this.start_meter_reading_second,
                start_meter_reading_third: this.start_meter_reading_third,
                date_start: this.date_start,
                date_end: this.date_end,
                prev_verification: this.prev_verification,
                next_verification: this.next_verification,
                serial_number: this.serial_number,
                model_id: this.model_id,
                active: this.active,
                disable_parent_service: this.disable_parent_service || false,
                disable_current_service: this.disable_current_service || false,
                change_start_reading: this.change_start_reading || false,
                seal_number_primary: this.seal_number_primary || null,
                seal_number_secondary: this.seal_number_secondary || null,
                seal_date_primary: this.seal_date_primary || null,
                seal_date_secondary: this.seal_date_secondary || null,
                counter_contractor_id: this.counter_contractor_id || null,
                unit_type_id: this.unit_type_id || null,
                lost_coefficient: this.lost_coefficient || null,
                counter_installation_place_id: this.counter_installation_place_id || null,
                counter_minus_id: this.counter_minus_id
            }

            if (this.isNew) {
                this.$store.dispatch(CREATE_FLAT_COUNTER, payload)
                    .then(el => {
                        if (el) {
                          this.$store.dispatch(GET_ALL_FLAT_COUNTER_OPERATION, this.flat_id)
                            this.closeModal("fetch")
                        }
                    })
            } else {
                payload.id = this.itemId
                this.$store.dispatch(UPDATE_FLAT_COUNTER, payload)
                    .then(el => {
                        if (el) {
                          this.$store.dispatch(GET_ALL_FLAT_COUNTER_OPERATION, this.flat_id)
                            this.closeModal("fetch")
                        }
                    })
            }
        },
        watch_dialog() {
            this.watcher = this.$watch(
                'dialog',
                {
                    immediate: true,
                    handler(payload) {
                        if (payload) {
                            this.watch_item()
                            this.watch_modal_answer()
                        } else {
                            if (this.watcher) {
                                this.watcher()
                            }
                            if (this.delete_watcher) {
                                this.delete_watcher()
                            }
                        }
                    }
                }
            )
        },
        watch_item() {
            this.watcher = this.$watch(
                'item',
                {
                    deep: true,
                    immediate: true,
                    handler(payload) {
                        this.itemId = payload.id
                        this.flat_id = payload.flat_id
                        this.zone = payload.zone || 0
                        this.service_id = payload.service_id || 0
                        this.start_meter_reading_first = payload.start_meter_reading_first || 0
                        this.start_meter_reading_second = payload.start_meter_reading_second || 0
                        this.start_meter_reading_third = payload.start_meter_reading_third || 0
                        this.date_start = payload.date_start || null
                        this.date_end = payload.date_end || null
                        this.prev_verification = payload.prev_verification || null
                        this.next_verification = payload.next_verification || null
                        this.verification_term = payload.verification_term || null
                        this.serial_number = payload.serial_number || ""
                        this.model_id = payload.model_id || 0
                        this.zones_counter = !(payload.zone === undefined || payload.zone === 0)
                        this.active = payload.active === undefined ? true : payload.active
                        this.disable_parent_service = payload.disable_parent_service || false
                        this.disable_current_service = payload.disable_current_service || false
                        this.change_start_reading = false
                        this.seal_number_primary = payload.seal_number_primary || null
                        this.seal_number_secondary = payload.seal_number_secondary || null
                        this.seal_date_primary = payload.seal_date_primary || null
                        this.seal_date_secondary = payload.seal_date_secondary || null
                        this.counter_contractor_id = payload.counter_contractor_id || null
                        this.unit_type_id = payload.unit_type_id || null
                        this.lost_coefficient = payload.lost_coefficient || null
                        this.counter_installation_place_id = payload.counter_installation_place_id || null
                        this.counter_minus_id = payload.counter_minus_id || null
                        this.tabs = 0
                    }
                }
            )
        },
        watch_modal_answer() {
            this.delete_watcher = this.$watch(
                'modalAnswer',
                {
                    handler(payload) {
                        if (payload.id === modalDeleteId) {
                            if (payload.answer) {
                                this.$store.dispatch(REMOVE_FLAT_COUNTER, this.itemId)
                                    .then(ok => {
                                        if (ok) {
                                          this.$store.dispatch(GET_ALL_FLAT_COUNTER_OPERATION, this.flat_id)
                                            this.closeModal("fetch")
                                        }
                                    })
                            }
                        }
                    }
                }
            )
        },
        modelChange(payload) {
            this.model_id = payload.value
            this.verification_term = payload.type
        },
        counterContractorChange(payload) {
            this.counter_contractor_id = payload.value
        },
        counterInstallationPlaceChange(payload) {
            this.counter_installation_place_id = payload.value
        },
        unitTypeChange(payload) {
            this.unit_type_id = payload.value
        },
        flatCounterChange(payload) {
            this.counter_minus_id = (payload || {}).value
        },
        changeZonezCounter() {
            if (this.zones_counter) {
                this.zone = 1
            } else {
                this.zone = 0
            }
        }
    },
    computed: {
        ...mapGetters({
            services: 'getServicesSelectWithCounter',
        }),
    }
}
</script>
